import axios from 'axios';
import { UserSession } from '@/users';
import store from '@/store';
// Set config defaults when creating the instance
const fetchAPI = axios.create({
  baseURL: config.VUE_APP_AXIOS_BASEURL_DJANGOAPI || process.env.VUE_APP_AXIOS_BASEURL_DJANGOAPI,
  timeout: 0,
});

const fetchLog = axios.create({ baseURL: config.VUE_APP_AXIOS_BASEURL_DJANGOAPI || process.env.VUE_APP_LOG_BASEURL });

const requestInterceptorHandler = (config) => config;
const requestErrorInterceptorHandler = (error) => Promise.reject(error);
const responseErrorInterceptorHandler = (error) => {
  if (error.response?.status === 401) UserSession.logoutSession(error);
  return Promise.reject(error);
};
const responseInterceptorHandler = (response) => response.data || response;

fetchAPI.interceptors.request.use(requestInterceptorHandler, requestErrorInterceptorHandler);
fetchLog.interceptors.request.use(requestInterceptorHandler, requestErrorInterceptorHandler);
fetchAPI.interceptors.response.use((response) => {
  const data = response.data || response;

  const { method, url } = response.config;

  if (['post', 'delete', 'put'].includes(method)) {
    fetchLog.post('/log/', {
      user: store.state.session.userID,
      // eslint-disable-next-line no-nested-ternary
      type: method === 'post' ? 'create' : (method === 'put' ? 'update' : 'delete'),
      source: url,
      information: {
        after: ['post', 'put'].includes(method) ? data : {},
        before: ['post'].includes(method) ? {} : (store.state.data.initialData || {}),
        fullName: `${store.state.session.firstName} ${store.state.session.lastName}`,
      },
    });

    store.state.data.initialData = null;
  }

  return data;
}, responseErrorInterceptorHandler);

fetchLog.interceptors.response.use(responseInterceptorHandler, responseErrorInterceptorHandler);

const fetchAPIWithoutInterceptor = axios.create({
  baseURL: config.VUE_APP_AXIOS_BASEURL_DJANGOAPI || process.env.VUE_APP_AXIOS_BASEURL_DJANGOAPI,
  timeout: 0,
});

export {
  fetchAPI,
  fetchLog,
  fetchAPIWithoutInterceptor,
};
